<template>
  <div>
    <ScenarioHeader :tabs="tabs" @onTabChange="handleTabChange" />
    <v-tabs-items v-model="tabs" class="mt-8" touchless>
      <v-tab-item>
        <IncreaseFunds />
      </v-tab-item>
      <v-tab-item>
        <StaticExample />
      </v-tab-item>
      <v-tab-item>
        <BuildYourOwnRM />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ScenarioHeader from '../ScenarioHeader.vue';
import IncreaseFunds from './IncreaseFunds.vue';
import StaticExample from './StaticExample.vue';
import BuildYourOwnRM from './BuildYourOwnRM/index.vue';

export default {
  data: () => ({
    tabs: null
  }),
  components: { ScenarioHeader, IncreaseFunds, StaticExample, BuildYourOwnRM },
  methods: {
    handleTabChange(tabs) {
      this.$data.tabs = tabs;
    }
  }
};
</script>

<style scoped></style>
