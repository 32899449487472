<template>
  <div v-if="isLoanOfficerLoaded">
    <div v-if="!isLoading">
      <ScenarioHeader
        v-if="currentScenarioTitle !== allScenariosTitles.scenarioATitle"
        :tabs="null"
        @onTabChange="() => {}"
      />
      <div v-if="currentScenarioTitle" class="d-flex flex-column">
        <div v-if="currentScenarioTitle === allScenariosTitles.scenarioATitle">
          <ScenarioA />
        </div>
        <div v-if="currentScenarioTitle === allScenariosTitles.scenarioBTitle">
          <ScenarioB />
        </div>
        <div v-if="currentScenarioTitle === allScenariosTitles.scenarioCTitle">
          <ScenarioC />
        </div>
        <div v-if="currentScenarioTitle === allScenariosTitles.scenarioDTitle">
          <ScenarioD />
        </div>
        <div v-if="currentScenarioTitle === allScenariosTitles.scenarioETitle">
          <ScenarioE />
        </div>
        <ILikeItButton v-if="!isResultSaved" />
      </div>
      <div v-if="currentScenarioTitle === ''" class="ma-10">
        <div v-for="(reason, index) in doesNotQualifyReasons" :key="index">
          <div v-if="index === 0" class="my-4 medium-text-font">
            {{ reason }}
          </div>
          <div v-else class="my-2 small-text-font">{{ reason }}</div>
        </div>
        <div class="text-center">
          <v-btn
            depressed
            rounded
            color="blue lighten-5"
            class="blue--text darken-5 align-self-center px-4"
            style="height: 100px"
            @click="goLeadCapture"
            x-large
          >
            <div class="pa-2" style="white-space: break-spaces">request Loan Officer review</div>
          </v-btn>
        </div>
        <div class="my-16">
          <div class="my-4 small-text-font" style="font-weight: 500">
            {{ loanOfficer.firstName }} {{ loanOfficer.lastName }}
          </div>
          <div class="my-4 small-text-font" style="font-weight: 500">
            NMLS {{ loanOfficer.nmls }}
          </div>
          <div class="my-4 small-text-font" style="font-weight: 500">
            {{ phoneNumberToShow }}
          </div>
          <a
            class="my-4 small-text-font"
            :href="`mailto:${loanOfficer.email}`"
            style="font-weight: 500"
            >{{ loanOfficer.email }}</a
          >
          <div class="my-4 small-text-font" style="font-weight: 500">
            {{ loanOfficer.title }}
          </div>
          <div class="my-4 small-text-font" style="font-weight: 500">
            {{ loanOfficer.companyName }}
          </div>
          <div class="my-4 small-text-font" style="font-weight: 500">
            NMLS {{ loanOfficer.employerNMLS }}
          </div>
          <div class="my-4 small-text-font" style="font-weight: 500">
            {{ loanOfficer.employerStateID }}
          </div>
          <div class="my-4 small-text-font" style="font-weight: 500">
            {{ loanOfficer.footerText }}
          </div>
        </div>
      </div>
    </div>
    <v-progress-linear
      :active="isLoading || isUpdating"
      :indeterminate="isLoading || isUpdating"
      fixed
      bottom
      height="7"
      color="headerTabs"
    ></v-progress-linear>
  </div>
</template>
<script>
import _ from 'lodash';

import ScenarioHeader from '../../components/ScenarioHeader.vue';
import ScenarioA from '../../components/ScenarioA/index.vue';
import ScenarioB from '../../components/ScenarioB/index.vue';
import ScenarioC from '../../components/ScenarioC/index.vue';
import ScenarioD from '../../components/ScenarioD/index.vue';
import ScenarioE from '../../components/ScenarioE/index.vue';
import ILikeItButton from '../../components/ILikeItButton.vue';

import { showErrorToast } from '../../helpers/showErrorToast';

export default {
  data() {
    return {
      loading: false
    };
  },
  components: {
    ILikeItButton,
    ScenarioHeader,
    ScenarioA,
    ScenarioB,
    ScenarioC,
    ScenarioD,
    ScenarioE
  },
  created() {
    if (!this.isLoading && _.isEmpty(this.allScenariosTitles)) {
      this.showErrorToast('There are no data to show, please go to the landing form and try again');
    }
  },
  methods: {
    goLeadCapture() {
      this.$router.push({ name: 'Lead Capture' });
    },
    showErrorToast
  },
  computed: {
    isResultSaved() {
      return this.$store.getters.isResultSaved;
    },
    lenderLoanNumber() {
      return this.$store.state.scenarioData.qrData.response?.Identification?.LenderLoanNumber;
    },
    currentScenarioTitle() {
      return this.$store.state.scenarioData.scenario;
    },
    allScenariosTitles() {
      return this.$store.state.scenarioData.allScenarioTitles;
    },
    doesNotQualifyReasons() {
      return this.$store.state.scenarioData.doesNotQualifyReasons;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    isUpdating() {
      return this.$store.state.isUpdating;
    },
    isLoanOfficerLoaded() {
      return this.$store.getters['loanOfficer/isLoanOfficerLoaded'];
    },
    loanOfficer() {
      return this.$store.state.loanOfficer.info;
    },
    landingData() {
      return this.$store.state.scenarioData.landingPageFormData;
    },
    phoneNumberToShow() {
      const parsedPhoneNumber = this.loanOfficer.phone;
      return parsedPhoneNumber;
    }
  }
};
</script>

<style scoped>
.display-flex {
  display: flex;
  justify-content: space-between;
}
.display-flex button {
  min-width: 64px !important;
  flex: inherit;
}
</style>
